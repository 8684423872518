<template>
  <v-avatar
    :size="size"
    :tile="fromSideBar"
    :class="{ acronym: alt && userImgError }"
    class="acronym"
  >
    <b
      v-if="(alt && userImgError) || !image"
      class="primary--text text-uppercase disable-select"
      :style="`font-size:${size <= 30 ? 13 : size / 4}px`"
    >
      <!-- acronym -->
      {{ acronym }}
    </b>
    <img
      v-else
      :width="size"
      :height="size"
      :src="getProfileImage(image)"
      :class="className"
      :alt="alt || 'profile'"
      @error="returnAcronym"
    />
  </v-avatar>
</template>

<script>
export default {
  name: "profile-image",
  props: {
    fromSideBar: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    size: {
      type: [Number, String],
      default: 50,
    },
  },
  data() {
    return {
      userImgError: false,
    };
  },
  methods: {
    returnAcronym(e) {
      this.userImgError = true;
      e.target.src = "/media/defaults/member.jpg";
    },
  },
  computed: {
    acronym() {
      let acronym = "";
      try {
        acronym = this.alt
          .match(/\b\w/g)
          .join("")
          .substring(0, 2);
      } catch (e) {
        acronym = "A";
      }
      return acronym;
    },
  },
};
</script>
<style lang="scss" scoped>
.acronym {
  background-color: #ebeef7;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-avatar', {
    staticClass: "acronym",
    class: {
      acronym: _vm.alt && _vm.userImgError
    },
    attrs: {
      "size": _vm.size,
      "tile": _vm.fromSideBar
    }
  }, [_vm.alt && _vm.userImgError || !_vm.image ? _c('b', {
    staticClass: "primary--text text-uppercase disable-select",
    style: "font-size:".concat(_vm.size <= 30 ? 13 : _vm.size / 4, "px")
  }, [_vm._v(" " + _vm._s(_vm.acronym) + " ")]) : _c('img', {
    class: _vm.className,
    attrs: {
      "width": _vm.size,
      "height": _vm.size,
      "src": _vm.getProfileImage(_vm.image),
      "alt": _vm.alt || 'profile'
    },
    on: {
      "error": _vm.returnAcronym
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-list color="background" two-line>
    <div v-if="loading">
      <template v-for="i in 7">
        <v-card :key="i" class="pa-3 radius-10 mb-3" outlined>
          <v-skeleton-loader type="list-item-avatar-two-line" />
        </v-card>
      </template>
    </div>
    <div v-if="!loading && getInactiveUserMessage">
      <no-data :first-text="getInactiveUserMessage"></no-data>
    </div>
    <div
      class="d-flex flex-center text-center text-muted min-h-200px"
      v-if="noData && !loading && !getInactiveUserMessage"
    >
      <div class="no-data-found mx-auto">
        <inline-svg
          class="mt-6 svg-icon empty_icon"
          :src="`/media/placeholders/all-done${
            $vuetify.theme.dark ? '-dark' : ''
          }.svg`"
        />
        <p class="mt-2 empty-title bold-text text-dark text-center">
          All caught up
        </p>
        <p
          class="mt-2 mb-5 empty-sub-title font-weight-normal text-dark text-center"
        >
          No new alerts
        </p>
      </div>
    </div>
    <v-list-item-group>
      <!-- <div class="d-flex align-center mb-2" v-if="unread">
        <v-spacer></v-spacer>
        <a style="font-size: 13px" @click="markAsRead()">mark all as read</a>
      </div> -->
      <!-- new read -->
      <!--  -->
      <!--  -->
      <v-card
        class="mb-3 rounded-lg new"
        style="overflow: hidden; position: relative"
        flat
        outlined
        v-for="item in unread"
        @click="notificationAction(item)"
        :key="`${item.created_at}`"
      >
        <span
          style="position: absolute; top: 10px; right: 14px; font-size: 12px"
        >
          <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
        </span>
        <v-list-item style="height: 75px">
          <!-- if there is a badge -->
          <v-badge
            :value="Object.keys(item.app).length"
            avatar
            v-if="!isEmpty(item.user)"
            bordered
            overlap
            class="mr-4"
          >
            <template v-slot:badge>
              <v-avatar v-if="item.app && item.app.image && !!item.app.image">
                <v-img :src="getAppImage(item.app)"></v-img>
              </v-avatar>
              <v-avatar v-else>
                <v-img :src="'/media/defaults/app_default.svg'"></v-img>
              </v-avatar>
            </template>
            <!-- user image if it's have an app -->
            <ProfileImage
              :image="item?.user?.image"
              :size="48"
              className="img-fluid align-self-center rounded-circle"
              :alt="item?.user?.name"
            />
          </v-badge>
          <ProfileImage
            v-if="isEmpty(item.user) && item.action === 'feedback'"
            class="mr-4"
            :image="
              get(item, 'app.image', null) || '/media/defaults/app_default.svg'
            "
            :size="48"
            className="img-fluid align-self-center rounded-circle"
            :alt="get(item, 'app.name', '')"
          />
          <!-- if there is no badge -->
          <!-- <v-list-item-avatar v-else size="48">
            <v-img :src="item.avatar"></v-img>
          </v-list-item-avatar> -->
          <!-- the notification's content -->
          <v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                class="bold-text text-capitalize mb-1"
                style="font-size: 12px"
                >{{ getNotificationsTitle(item) }}
              </v-list-item-title>
              <div style="font-size: 14px; max-width: 83%">
                {{ getNotificationContent(item) }}
              </div>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <!-- read's read -->
      <!--  -->
      <!--  -->
      <v-card
        class="mb-3 rounded-lg"
        style="overflow: hidden; position: relative"
        flat
        outlined
        v-for="(item, i) in read"
        @click="notificationAction(item)"
        :key="i"
      >
        <span
          style="position: absolute; top: 10px; right: 14px; font-size: 12px"
        >
          <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
        </span>
        <v-list-item style="height: 75px">
          <!-- if there is a badge -->
          <v-badge
            avatar
            v-if="!isEmpty(item.user)"
            :value="Object.keys(item.app).length"
            bordered
            overlap
            class="mr-4"
          >
            <template v-slot:badge>
              <v-avatar v-if="item.app && item.app.id && !!item.app.image">
                <v-img :src="getAppImage(item.app)"></v-img>
              </v-avatar>
              <v-avatar v-else>
                <v-img :src="'/media/defaults/app_default.svg'"></v-img>
              </v-avatar>
            </template>
            <!-- user image if it's have an app -->
            <ProfileImage
              :image="item?.user?.image"
              :size="48"
              className="img-fluid align-self-center rounded-circle"
              :alt="item?.user?.name"
            />
          </v-badge>
          <ProfileImage
            v-if="isEmpty(item.user) && item.action === 'feedback'"
            class="mr-4"
            :image="
              get(item, 'app.image', null) || '/media/defaults/app_default.svg'
            "
            :size="48"
            className="img-fluid align-self-center rounded-circle"
            :alt="get(item, 'app.name', '')"
          />
          <v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                class="bold-text text-capitalize mb-1"
                style="font-size: 12px"
                >{{ getNotificationsTitle(item) }}
              </v-list-item-title>
              <div style="font-size: 14px; max-width: 83%">
                {{ getNotificationContent(item) }}
              </div>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-list-item-group>

    <infinate-loading-base
      :endpoint="endpoint"
      :reset="true"
      :distance="100"
      from-alert-notification
      @update-loading="loading = $event"
      @setInfiniteResponse="setInfiniteResponse"
      @resetInfiniteResponse="resetInfiniteResponse"
    ></infinate-loading-base>
    <Modal
      v-model="showInviteDrawer"
      class="invited"
      persistent
      @onClose="showInviteDrawer = false"
    >
      <template #message>
        <invite-action-modal
          v-if="showInviteDrawer"
          :invite-id="currentInvite"
          @close="(showInviteDrawer = false), $emit('close')"
        />
      </template>
    </Modal>
  </v-list>
</template>

<script>
import { isEmpty, get } from "@/core/services/helper.service";
import InviteActionModal from "@/view/components/Invites/ActionModal.vue";
import ProfileImage from "@/view/components/User/Image.vue";
import InfinateLoadingBase from "@/view/components/Common/InfinateLoadingBase.vue";
import { GET_NOTIFICATIONS } from "@/store/users/notifications.module";
import { mapGetters } from "vuex";

export default {
  // props: ["unread", "read"],
  components: { ProfileImage, InviteActionModal, InfinateLoadingBase },
  data() {
    return {
      get,
      isEmpty,
      showInviteDrawer: false,
      currentInvite: null,
      read: [],
      loading: false,
      endpoint: GET_NOTIFICATIONS,
      unread: [],
      actionMap: {
        team: () => `/team/members`,
        app: (id) => `/app/${id}/dashboard`,
        invite: (id) => `/app/${id}/members`,
        release: (appId, releaseId) => `/app/${appId}/release/${releaseId}`,
        comment: (appId, releaseId, commentId) =>
          `/app/${appId}/release/${releaseId}/comment/${commentId}`,
      },
    };
  },
  created() {},
  beforeDestroy() {
    this.read = [];
    this.unread = [];
  },
  computed: {
    ...mapGetters({
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    noData() {
      return isEmpty(this.unread) && isEmpty(this.read) ? true : false;
    },
  },
  methods: {
    setInfiniteResponse(data) {
      data.map((v) => {
        if (v.read == 1) {
          this.read.push(v);
        } else {
          this.unread.push(v);
        }
      });
    },
    resetInfiniteResponse() {
      this.read = [];
      this.unread = [];
    },
    markAsRead() {
      // this.$emit("markAsRead");
    },
    getNotificationContent(details) {
      if (details.action === "app") {
        return `${details.content} you to ${details.app.name}`;
      } else if (details.action === "invite") {
        if (details.sub_action === "new") {
          return `${details.content} you to join ${details.team.name}`;
        } else if (details.sub_action === "status") {
          return `${details.content} for ${details.team.name}`;
        }
      } else if (
        ["release", "release_comment", "feedback"].includes(details.action)
      ) {
        return `${details.content}`;
      }
    },
    getNotificationsTitle(detail) {
      if (detail.action === "feedback") {
        if (detail && detail.user && detail.user.name) {
          return detail.user.name;
        } else {
          return get(detail, "app.name", "");
        }
      } else {
        return detail.user && detail.user.name ? detail.user.name : "";
      }
    },
    notificationAction(details) {
      if (details.action === "app") {
        this.$emit("close");
        if (!this.canManageApp) {
          this.$router.push(this.actionMap.app(details.app.id)).catch(() => {});
        } else {
          this.$router.push(this.actionMap.app(details.app.id)).catch(() => {});
        }
      } else if (details.action === "invite") {
        if (details.sub_action === "new") {
          this.currentInvite = details.meta.id;
          this.showInviteDrawer = true;
        } else if (details.sub_action === "status") {
          this.$emit("close");
          if (details.team && details.team.id) {
            if (this.$route.path !== "/team/members") {
              this.$router.push(this.actionMap.team()).catch(() => {});
            }
          }
        }
      } else if (details.action === "release") {
        this.$emit("close");
        this.$router
          .push(this.actionMap.release(details.app.id, details.meta.id))
          .catch(() => {});
      } else if (details.action === "release_comment") {
        this.$emit("close");
        this.$router
          .push(
            this.actionMap.comment(
              details?.app?.id,
              details?.meta?.release_id,
              details?.meta?.id
            )
          )
          .catch(() => {});
      } else if (details.action === "feedback") {
        this.$emit("close");
        this.$router.push({
          name: "app-feedback",
          params: { app_id: details.app.id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.new {
  background-color: rgba($blue, 0.1) !important;
  border-color: $blue !important;
  .v-list-item__subtitle {
    color: $blue !important;
  }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('Notification', {
    attrs: {
      "from-page": "",
      "notification-stats": _vm.userNotifications || {}
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top">
      <v-card-title v-if="!fromPage" class="mb-7 display-1 justify-center">
        Notifications
      </v-card-title>
      <!--  -->
      <div class="small-tabs">
        <v-tabs v-model="tab">
          <v-tab>
            <span :class="{ 'darkGrey--text': $vuetify.theme.dark }">
              alerts
            </span>
            <v-chip class="tab-chip mx-1" v-if="notificationStats.alerts">
              {{
                notificationStats.alerts > 99 ? "99+" : notificationStats.alerts
              }}
            </v-chip>
          </v-tab>
          <v-tab>
            <span :class="{ 'darkGrey--text': $vuetify.theme.dark }">
              invites
            </span>
            <v-chip class="tab-chip mx-1" v-if="notificationStats.invites">
              {{
                `${notificationStats.invites}`.includes("-")
                  ? 0
                  : notificationStats.invites > 99
                  ? "99+"
                  : notificationStats.invites
              }}
            </v-chip>
          </v-tab>

          <v-tab-item class="notification-area">
            <!-- loader -->
            <div v-if="loader.alert || loader">
              <template v-for="i in 7">
                <v-card :key="i" class="pa-3 radius-10 mb-3" outlined>
                  <v-skeleton-loader type="list-item-avatar-two-line" />
                </v-card>
              </template>
            </div>
            <!-- /loader -->
            <notifications
              v-else
              :unread="allNotifications.alert.unread"
              :read="allNotifications.alert.read"
              @close="$emit('close')"
            />
          </v-tab-item>
          <v-tab-item class="notification-area">
            <!--  -->
            <invitesNotifications />
            <!-- <notifications
              :read="allNotifications.invite"
            /> -->
            <!--  -->
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import notifications from "@/view/components/User/Notification/alerts.vue";
import invitesNotifications from "@/view/components/User/Notification/invites.vue";
import { get } from "@/core/services/helper.service";

export default {
  name: "KTDropdownNotification",
  props: {
    fromPage: {
      type: Boolean,
      default: false,
    },
    notificationStats: {
      type: Object,
      required: true,
      default() {
        return {
          alerts: 0,
          invites: 0,
        };
      },
    },
  },
  components: {
    notifications,
    invitesNotifications,
  },
  data() {
    return {
      tabIndex: 0,
      tab: 0,
      loader: false,
      loaderData: true,
    };
  },
  methods: {
    ...mapMutations({
      updateNotifications: "updateNotification",
    }),
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
  },
  computed: {
    ...mapGetters({
      userNotificationsStats: "userNotificationsStats",
    }),
    ...mapState({
      allNotifications: (state) =>
        get(state, "notifications.allNotifications", ""),
    }),
  },
  async mounted() {
    this.loader = true;
    this.updateNotifications({
      alerts: 0,
      invites: this.userNotificationsStats.invites,
    });
    this.loader = false;
  },
};
</script>

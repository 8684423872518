<template>
    <v-row>
        <v-col class="mt-4" cols="12" md="6">
            <Notification from-page :notification-stats="userNotifications || {}" />
        </v-col>
    </v-row>
</template>

<script>
import Notification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        Notification,
    },
    computed: {
        ...mapGetters({
            userNotifications: "userNotificationsStats",
        }),
    },
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    attrs: {
      "color": "background",
      "two-line": ""
    }
  }, [_vm.loading ? _c('div', [_vm._l(7, function (i) {
    return [_c('v-card', {
      key: i,
      staticClass: "pa-3 radius-10 mb-3",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "list-item-avatar-two-line"
      }
    })], 1)];
  })], 2) : _vm._e(), !_vm.loading && _vm.getInactiveUserMessage ? _c('div', [_c('no-data', {
    attrs: {
      "first-text": _vm.getInactiveUserMessage
    }
  })], 1) : _vm._e(), _vm.noData && !_vm.loading && !_vm.getInactiveUserMessage ? _c('div', {
    staticClass: "d-flex flex-center text-center text-muted min-h-200px"
  }, [_c('div', {
    staticClass: "no-data-found mx-auto"
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/all-done".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }), _c('p', {
    staticClass: "mt-2 empty-title bold-text text-dark text-center"
  }, [_vm._v(" All caught up ")]), _c('p', {
    staticClass: "mt-2 mb-5 empty-sub-title font-weight-normal text-dark text-center"
  }, [_vm._v(" No new alerts ")])], 1)]) : _vm._e(), _c('v-list-item-group', [_vm._l(_vm.unread, function (item) {
    var _item$user, _item$user2;
    return _c('v-card', {
      key: "".concat(item.created_at),
      staticClass: "mb-3 rounded-lg new",
      staticStyle: {
        "overflow": "hidden",
        "position": "relative"
      },
      attrs: {
        "flat": "",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.notificationAction(item);
        }
      }
    }, [_c('span', {
      staticStyle: {
        "position": "absolute",
        "top": "10px",
        "right": "14px",
        "font-size": "12px"
      }
    }, [_c('vue-hoverable-date', {
      attrs: {
        "date": item.created_at
      }
    })], 1), _c('v-list-item', {
      staticStyle: {
        "height": "75px"
      }
    }, [!_vm.isEmpty(item.user) ? _c('v-badge', {
      staticClass: "mr-4",
      attrs: {
        "value": Object.keys(item.app).length,
        "avatar": "",
        "bordered": "",
        "overlap": ""
      },
      scopedSlots: _vm._u([{
        key: "badge",
        fn: function fn() {
          return [item.app && item.app.image && !!item.app.image ? _c('v-avatar', [_c('v-img', {
            attrs: {
              "src": _vm.getAppImage(item.app)
            }
          })], 1) : _c('v-avatar', [_c('v-img', {
            attrs: {
              "src": '/media/defaults/app_default.svg'
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('ProfileImage', {
      attrs: {
        "image": item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.image,
        "size": 48,
        "className": "img-fluid align-self-center rounded-circle",
        "alt": item === null || item === void 0 ? void 0 : (_item$user2 = item.user) === null || _item$user2 === void 0 ? void 0 : _item$user2.name
      }
    })], 1) : _vm._e(), _vm.isEmpty(item.user) && item.action === 'feedback' ? _c('ProfileImage', {
      staticClass: "mr-4",
      attrs: {
        "image": _vm.get(item, 'app.image', null) || '/media/defaults/app_default.svg',
        "size": 48,
        "className": "img-fluid align-self-center rounded-circle",
        "alt": _vm.get(item, 'app.name', '')
      }
    }) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "bold-text text-capitalize mb-1",
      staticStyle: {
        "font-size": "12px"
      }
    }, [_vm._v(_vm._s(_vm.getNotificationsTitle(item)) + " ")]), _c('div', {
      staticStyle: {
        "font-size": "14px",
        "max-width": "83%"
      }
    }, [_vm._v(" " + _vm._s(_vm.getNotificationContent(item)) + " ")])], 1)], 1)], 1)], 1);
  }), _vm._l(_vm.read, function (item, i) {
    var _item$user3, _item$user4;
    return _c('v-card', {
      key: i,
      staticClass: "mb-3 rounded-lg",
      staticStyle: {
        "overflow": "hidden",
        "position": "relative"
      },
      attrs: {
        "flat": "",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.notificationAction(item);
        }
      }
    }, [_c('span', {
      staticStyle: {
        "position": "absolute",
        "top": "10px",
        "right": "14px",
        "font-size": "12px"
      }
    }, [_c('vue-hoverable-date', {
      attrs: {
        "date": item.created_at
      }
    })], 1), _c('v-list-item', {
      staticStyle: {
        "height": "75px"
      }
    }, [!_vm.isEmpty(item.user) ? _c('v-badge', {
      staticClass: "mr-4",
      attrs: {
        "avatar": "",
        "value": Object.keys(item.app).length,
        "bordered": "",
        "overlap": ""
      },
      scopedSlots: _vm._u([{
        key: "badge",
        fn: function fn() {
          return [item.app && item.app.id && !!item.app.image ? _c('v-avatar', [_c('v-img', {
            attrs: {
              "src": _vm.getAppImage(item.app)
            }
          })], 1) : _c('v-avatar', [_c('v-img', {
            attrs: {
              "src": '/media/defaults/app_default.svg'
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('ProfileImage', {
      attrs: {
        "image": item === null || item === void 0 ? void 0 : (_item$user3 = item.user) === null || _item$user3 === void 0 ? void 0 : _item$user3.image,
        "size": 48,
        "className": "img-fluid align-self-center rounded-circle",
        "alt": item === null || item === void 0 ? void 0 : (_item$user4 = item.user) === null || _item$user4 === void 0 ? void 0 : _item$user4.name
      }
    })], 1) : _vm._e(), _vm.isEmpty(item.user) && item.action === 'feedback' ? _c('ProfileImage', {
      staticClass: "mr-4",
      attrs: {
        "image": _vm.get(item, 'app.image', null) || '/media/defaults/app_default.svg',
        "size": 48,
        "className": "img-fluid align-self-center rounded-circle",
        "alt": _vm.get(item, 'app.name', '')
      }
    }) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "bold-text text-capitalize mb-1",
      staticStyle: {
        "font-size": "12px"
      }
    }, [_vm._v(_vm._s(_vm.getNotificationsTitle(item)) + " ")]), _c('div', {
      staticStyle: {
        "font-size": "14px",
        "max-width": "83%"
      }
    }, [_vm._v(" " + _vm._s(_vm.getNotificationContent(item)) + " ")])], 1)], 1)], 1)], 1);
  })], 2), _c('infinate-loading-base', {
    attrs: {
      "endpoint": _vm.endpoint,
      "reset": true,
      "distance": 100,
      "from-alert-notification": ""
    },
    on: {
      "update-loading": function updateLoading($event) {
        _vm.loading = $event;
      },
      "setInfiniteResponse": _vm.setInfiniteResponse,
      "resetInfiniteResponse": _vm.resetInfiniteResponse
    }
  }), _c('Modal', {
    staticClass: "invited",
    attrs: {
      "persistent": ""
    },
    on: {
      "onClose": function onClose($event) {
        _vm.showInviteDrawer = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.showInviteDrawer ? _c('invite-action-modal', {
          attrs: {
            "invite-id": _vm.currentInvite
          },
          on: {
            "close": function close($event) {
              _vm.showInviteDrawer = false, _vm.$emit('close');
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showInviteDrawer,
      callback: function callback($$v) {
        _vm.showInviteDrawer = $$v;
      },
      expression: "showInviteDrawer"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
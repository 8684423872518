var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('infinite-loading', {
    ref: "infiniteLoading",
    attrs: {
      "distance": 500,
      "force-use-infinite-wrapper": true,
      "spinner": "waveDots"
    },
    on: {
      "infinite": _vm.infiniteHandler
    }
  }, [_c('span', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }), _c('span', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
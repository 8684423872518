var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"
  }, [!_vm.fromPage ? _c('v-card-title', {
    staticClass: "mb-7 display-1 justify-center"
  }, [_vm._v(" Notifications ")]) : _vm._e(), _c('div', {
    staticClass: "small-tabs"
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_c('span', {
    class: {
      'darkGrey--text': _vm.$vuetify.theme.dark
    }
  }, [_vm._v(" alerts ")]), _vm.notificationStats.alerts ? _c('v-chip', {
    staticClass: "tab-chip mx-1"
  }, [_vm._v(" " + _vm._s(_vm.notificationStats.alerts > 99 ? "99+" : _vm.notificationStats.alerts) + " ")]) : _vm._e()], 1), _c('v-tab', [_c('span', {
    class: {
      'darkGrey--text': _vm.$vuetify.theme.dark
    }
  }, [_vm._v(" invites ")]), _vm.notificationStats.invites ? _c('v-chip', {
    staticClass: "tab-chip mx-1"
  }, [_vm._v(" " + _vm._s("".concat(_vm.notificationStats.invites).includes("-") ? 0 : _vm.notificationStats.invites > 99 ? "99+" : _vm.notificationStats.invites) + " ")]) : _vm._e()], 1), _c('v-tab-item', {
    staticClass: "notification-area"
  }, [_vm.loader.alert || _vm.loader ? _c('div', [_vm._l(7, function (i) {
    return [_c('v-card', {
      key: i,
      staticClass: "pa-3 radius-10 mb-3",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "list-item-avatar-two-line"
      }
    })], 1)];
  })], 2) : _c('notifications', {
    attrs: {
      "unread": _vm.allNotifications.alert.unread,
      "read": _vm.allNotifications.alert.read
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  })], 1), _c('v-tab-item', {
    staticClass: "notification-area"
  }, [_c('invitesNotifications')], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    attrs: {
      "color": "background",
      "two-line": ""
    }
  }, [_c('v-list-item-group', [_vm.loader ? _c('div', [_vm._l(7, function (i) {
    return [_c('v-card', {
      key: i,
      staticClass: "pa-3 radius-10 mb-3",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "list-item-avatar-two-line"
      }
    })], 1)];
  })], 2) : _vm._e(), _vm.noData && !_vm.loader ? _c('div', {
    staticClass: "d-flex flex-center text-center text-muted min-h-200px"
  }, [_c('div', {
    staticClass: "no-data-found mx-auto"
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/all-done".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }), _c('p', {
    staticClass: "mt-2 empty-title bold-text text-dark text-center"
  }, [_vm._v(" All caught up ")]), _c('p', {
    staticClass: "mt-2 mb-5 empty-sub-title font-weight-normal text-dark text-center"
  }, [_vm._v(" No new invites ")])], 1)]) : _vm._l(_vm.getNotificationInviteList, function (item, i) {
    var _item$from_user, _item$from_user2, _item$team;
    return _c('v-card', {
      key: i,
      staticClass: "mb-3 rounded-lg",
      staticStyle: {
        "overflow": "hidden",
        "position": "relative"
      },
      attrs: {
        "flat": "",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openInviteActionForm(item.id);
        }
      }
    }, [_c('span', {
      staticStyle: {
        "position": "absolute",
        "top": "10px",
        "right": "14px",
        "font-size": "12px"
      }
    }, [_c('vue-hoverable-date', {
      attrs: {
        "date": item.created_at
      }
    })], 1), _c('v-list-item', {
      staticStyle: {
        "height": "75px"
      },
      on: {
        "click": function click($event) {
          return _vm.openInviteDrawer(item);
        }
      }
    }, [_c('ProfileImage', {
      attrs: {
        "image": item === null || item === void 0 ? void 0 : (_item$from_user = item.from_user) === null || _item$from_user === void 0 ? void 0 : _item$from_user.image,
        "size": 48,
        "className": "img-fluid align-self-center rounded-circle",
        "alt": item === null || item === void 0 ? void 0 : (_item$from_user2 = item.from_user) === null || _item$from_user2 === void 0 ? void 0 : _item$from_user2.name
      }
    }), _c('v-list-item-content', {
      staticClass: "ml-2"
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "bold-text text-capitalize mb-1",
      staticStyle: {
        "font-size": "12px"
      }
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.from_user.name) + " ")]), _c('div', {
      staticStyle: {
        "font-size": "14px",
        "max-width": "83%"
      }
    }, [_vm._v(" " + _vm._s("Invited you to join ".concat(item === null || item === void 0 ? void 0 : (_item$team = item.team) === null || _item$team === void 0 ? void 0 : _item$team.name)) + " ")])], 1)], 1)], 1)], 1);
  })], 2), _c('infinate-loading-base', {
    attrs: {
      "endpoint": _vm.endpoint,
      "reset": true,
      "distance": 200
    },
    on: {
      "update-loading": function updateLoading($event) {
        _vm.loader = $event;
      },
      "setInfiniteResponse": _vm.setInfiniteResponse,
      "resetInfiniteResponse": _vm.resetInfiniteResponse
    }
  }), _c('Modal', {
    on: {
      "onClose": function onClose($event) {
        _vm.showInviteDrawer = false;
      }
    },
    model: {
      value: _vm.showInviteDrawer,
      callback: function callback($$v) {
        _vm.showInviteDrawer = $$v;
      },
      expression: "showInviteDrawer"
    }
  }, [_c('template', {
    slot: "message"
  }, [_vm.showInviteDrawer ? _c('invite-action-modal', {
    attrs: {
      "current-invite-details": _vm.currentInvite
    },
    on: {
      "close": function close($event) {
        _vm.showInviteDrawer = false;
      }
    }
  }) : _vm._e()], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }